<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";
import axios from "axios";
import { API_URL } from "@/core/api";
// import { initFbsdk } from "@/core/config/facebook.js";
import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import {
  ArrowUpIcon,
  UserIcon,
  MailIcon,
  BookIcon,
  MessageCircleIcon,
} from "vue-feather-icons";

import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";

/**
 * Page-contact-one component
 */
export default {
  data() {
    return {
      state: "signin",
      form: {
        email: "",
        password: "",
      },
      apiUrl: API_URL,
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    UserIcon,
    ArrowUpIcon,
    MailIcon,
    BookIcon,
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
  },
  mounted() {
    // initFbsdk();
    const signin_form = KTUtil.getById("kt_login_signin_form");

    this.fv1 = formValidation(signin_form, {
      fields: {
        name: {
          validators: {
            notEmpty: {
              message: "Full name is required",
            },
          },
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
        subject: {
          validators: {
            notEmpty: {
              message: "Subject is required",
            },
          },
        },
        comments: {
          validators: {
            notEmpty: {
              message: "Comment is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
  },
  methods: {
    // showForm(form) {
    //   // console.log("test");
    //   this.state = form;
    //   var form_name = "kt_login_" + form + "_form";
    //   KTUtil.animateClass(
    //     KTUtil.getById(form_name)
    //     // "animate__animated animate__backInUp"
    //   );
    // },

    onSubmitLogin() {
      console.log("hittttttttttttttttttt");
      this.fv1.validate();
      this.fv1.validate().then((res) => {
        if (res == "Valid") {
          const submitButton = this.$refs["kt_login_signin_submit"];
          submitButton.classList.add(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          let formData = new FormData();
          formData.append("name", this.form.name);
          formData.append("email", this.form.email);
          formData.append("subject", this.form.subject);
          formData.append("comments", this.form.comments);

          axios.post(`${API_URL}/contactform`, formData).then((data) => {
            console.log(data);
            const submitButton = this.$refs["kt_login_signin_submit"];
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            if (data.data === "Email has been sent") {
              Swal.fire({
                title: "",
                text: "The message has been sent successfully. Thank you for contacting us. One of our team members will get in touch, if needed.",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
              // .then(function () {
              //   window.location = "/set-password";
              // });
            } else {
              if (data.data === "No Email") {
                Swal.fire({
                  title: "",
                  text: "We have not found account on this mail.",
                  icon: "error",
                  confirmButtonClass: "btn btn-secondary",
                  heightAuto: false,
                });
              } else {
                Swal.fire({
                  title: "",
                  text: "Have some issues please Some waite and try again",
                  icon: "error",
                  confirmButtonClass: "btn btn-secondary",
                  heightAuto: false,
                });
              }
            }
          });
        } else {
          const submitButton = this.$refs["kt_login_signin_submit"];
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          Swal.fire({
            title: "",
            text: "Please, provide correct data!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        }
      });

      // console.log("login hit ht"),
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      });
    },
  },
};
</script>

<template>
  <div>
    <Navbar />
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <section class="contact_image_bg">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4 auth_heading">
            We would love to hear from
            <span class="font-weight-bolder"> You..!</span>
          </div>
          <div class="col-lg-5 col-md-6">
            <div class="card shadow p-9 px-10">
              <div class="card-body p-0">
                <h4 class="card-title auth_title">Contact us</h4>
                <p class="card-title auth_disctption">
                  We endavour to respond within 24 hours, from Monday to Friday
                  09:00 AM to 06:00 PM (PST).
                </p>
                <div class="custom-form mt-4">
                  <div id="message"></div>
                  <form
                    novalidate="novalidate"
                    id="kt_login_signin_form"
                    @submit.stop.prevent="onSubmitLogin()"
                    name="contact-form"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div
                          class="form-group auth_inputs_icon position-relative"
                        >
                          <user-icon class="fea icons"></user-icon>
                          <input
                            name="name"
                            id="name"
                            ref="name"
                            v-model="form.name"
                            type="text"
                            class="form-control pl-5"
                            placeholder="Your Name :"
                          />
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div
                          class="form-group auth_inputs_icon position-relative"
                        >
                          <mail-icon class="fea icons"></mail-icon>
                          <input
                            name="email"
                            id="email"
                            ref="email"
                            v-model="form.email"
                            type="email"
                            class="form-control pl-5"
                            placeholder="Your email :"
                          />
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div
                          class="form-group auth_inputs_icon position-relative"
                        >
                          <book-icon class="fea icons"></book-icon>
                          <input
                            name="subject"
                            id="subject"
                            ref="subject"
                            v-model="form.subject"
                            type="text"
                            class="form-control pl-5"
                            placeholder="Subject"
                          />
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div
                          class="form-group auth_inputs_icon position-relative"
                        >
                          <textarea
                            name="comments"
                            id="comments"
                            ref="comments"
                            v-model="form.comments"
                            rows="4"
                            class="form-control pl-5"
                            placeholder="Your Message :"
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12 text-center">
                        <input
                          type="button"
                          id="submit"
                          name="send"
                          @click="onSubmitLogin()"
                          class="submitBnt btn btn-block"
                          ref="kt_login_signin_submit"
                          value="Send Message"
                        />
                        <div id="simple-msg"></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    <Switcher />
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
  </div>
</template>
